<template>
    <div class="table-page">
        <el-breadcrumb separator="/" style="margin-bottom: 20px">
            <el-breadcrumb-item :to="{ path: '/main/dashboard' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>门诊叫号</el-breadcrumb-item>
            <el-breadcrumb-item>叫号屏幕</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="margin:10px 0;">
            叫号屏幕页：
            <router-link target="_blank" :to="{ path:'/daping/dating', query: { id:'1' } }" style="margin:0 10px">
                <el-button size="large">铭德医院(大厅)</el-button>
            </router-link>
            <el-button @click="todo" size="large">北大健康东城分院(大厅)</el-button>
            <el-button @click="todo" size="large">北大健康西城分院(大厅)</el-button>
        </div>
        <div class="dept_name">铭德医院</div>
        <div>
            <router-link target="_blank" :to="{ path:'/daping/zhenshi', query: { id: item.id} }" style="margin:0 10px" v-for="(item, idx) in mingde" :key="idx">
                <el-button class="dept_btn" @click="todo" size="large">
                    {{item.name}} ({{item.department.name}})
                </el-button>
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                mingde: [],
                search: {},
            }
        },
        methods: {
            async getData() {
                let params = this.search
                params['hospital_id'] = 1
                const resp = await this.$http.get('/mingde/room/', {params: params})
                this.mingde = resp.data.data.data
            },
            handleSearch() {
                this.table.page = 1
                this.getData()
            },
            todo() {
                this.$message("需对接大屏")
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    .dept_name {
        font-size: 120%;
        text-align: center;
        margin-top:20px;
    }
    .dept_btn {
        margin: 10px;
        width: 200px
    }
</style>
